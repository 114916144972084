import React from "react"
import { useTranslation } from "react-i18next"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => {
  const { t } = useTranslation("404")
  const tr = (value: string): string => t(value)

  return (
    <Layout>
      <h1>{tr("notFound")}</h1>
    </Layout>
  )
}

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
